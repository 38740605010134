<template>
  <el-card class="box-card">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item name="1">
        <template slot="title">
          <i class="header-icon el-icon-search"></i> 搜索
        </template>
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item label="用户账号:">
            <el-input
                v-model.trim="queryForm.name"
                clearable
                placeholder="用户ID/账号"
                size="small"
            />
          </el-form-item>
          <el-form-item label="用户昵称:">
            <el-input
                v-model.trim="queryForm.nickname"
                clearable
                placeholder="用户昵称"
                size="small"
            />
          </el-form-item>
          <el-form-item label="用户等级:">
            <el-select
                v-model="queryForm.u_l_id"
                clearable
                placeholder="请选择"
                size="small"
            >
              <el-option
                  v-for="item in apiULId"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" @click="queryData" type="primary" plain size="small">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>

    <vab-query-form-left-panel>
      <el-button class="button" type="primary" @click="handleEdit" size="small">批量修改</el-button>
      <el-button class="button" type="primary" @click="exportExcel" size="small">导出数据</el-button>
    </vab-query-form-left-panel>

    <el-table
        :data="list"
        @selection-change="setSelectRows"
        style="width: 100%">
      <el-table-column align="center" show-overflow-tooltip type="selection" />
      <el-table-column
          align="center"
          prop="id"
          label="用户ID"
          width="80">
      </el-table-column>
      <el-table-column
          align="center"
          prop="name"
          label="用户账号">
      </el-table-column>
      <el-table-column
          align="center"
          prop="nickname"
          label="用户昵称">
      </el-table-column>
      <el-table-column
          align="center"
          prop="u_l_id"
          label="用户等级">
      </el-table-column>
      <el-table-column
          align="center"
          prop="credit"
          label="可用积分">
      </el-table-column>
      <el-table-column
          align="center"
          prop="credit_history"
          label="历史积分">
      </el-table-column>
      <el-table-column
          align="center"
          prop="money_balance"
          label="个人余额">
      </el-table-column>
      <el-table-column
          align="center"
          prop="available_balance"
          label="可用余额">
      </el-table-column>
      <el-table-column
          align="center"
          prop="gift_balance"
          label="赠送余额">
      </el-table-column>
      <el-table-column
          align="center"
          prop="growth_value"
          label="成长值">
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="obviousIntegral(row)">积分明细</el-button>
          <el-button type="text" @click="growthValueDetails(row)">成长值明细</el-button>
          <el-button type="text" @click="handleEdit(row)">修改数值</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit" />
  </el-card>
</template>
<script>
import Edit from './components/assets_Edit'

export default {
  name: 'personalAssets',
  components: { Edit },
  props: [],
  data() {
    return {
      activeName: '1',
      imgShow: true,
      apiULId: [],
      selectRows: '',
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        name: '',
        nickname: '',
        u_l_id: '',
      },
      list: [
        {
          id: '1',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '0',
        },
        {
          id: '2',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '1',
        },
        {
          id: '3',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '2',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
    this.requestULId()
  },
  mounted() {
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit({ id: row.id })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$refs['Edit'].showEdit({ id })
        } else {
          this.$message.success('未选中任何行!')
          return false
        }
      }
    },
    obviousIntegral(row) {
      if (row.id) {
        this.$router.push({
          path: 'obviousIntegral',
          query: {
            id: row.id,
          },
        })
      }
    },
    growthValueDetails(row) {
      if (row.id) {
        this.$router.push({
          path: 'growthValueDetails',
          query: {
            id: row.id,
          },
        })
      }
    },
    // 获取用户等级
    async requestULId() {
      const { data } = await matchBranchList()
      this.apiULId = data
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, async () => {
          const { code, message } = await doDelete({ id: row.id })
          if (code == '200') {
            this.$message.success(message)
            await this.fetchData()
          } else {
            this.$message.error(message)
            await this.fetchData()
          }
        })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$confirm('你确定要删除选中项吗', null, async () => {
            const { code, message } = await doDelete({ id })
            if (code == '200') {
              this.$message.success(message)
              await this.fetchData()
            } else {
              this.$message.error(message)
              await this.fetchData()
            }
          })
        } else {
          this.$message.error('未选中任何行')
          return false
        }
      }
    },
    // 导出
    async exportExcel() {
      const { data } = await exportOfflineMoney()
      console.log(data)
      window.location.href = data[0]
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const { data } = await getList(this.queryForm)
      this.list = data.list
      this.count = data.count
      this.count = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++
      this.listLoading = false
    },
  }
}

</script>
<style>
  .button {
    margin-top: 20px;
  }

  .el-pagination {
    text-align: center;
    margin-top: 20px;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .el-dropdown {
    margin-right: 10px;
  }

  .page {
    background-color: #FFF;
    padding: 20px;
    margin: 20px;
  }

  .el-row {
    margin-bottom: 15px;
  }
</style>
