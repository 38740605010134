<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="550px"
      @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="调整积分" prop="is_credit">
        <el-switch
            v-model="form.is_credit"
            active-color="#13ce66"
            active-text="增加"
            active-value="1"
            inactive-color="#ff4949"
            inactive-text="减少"
            inactive-value="0"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="" prop="credit">
        <el-input-number v-model="form.credit" step-strictly controls-position="right" :min="1" size="small"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="调整成长值" prop="is_growth_value">
        <el-switch
            v-model="form.is_growth_value"
            active-color="#13ce66"
            active-text="增加"
            active-value="1"
            inactive-color="#ff4949"
            inactive-text="减少"
            inactive-value="0"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="" prop="growth_value">
        <el-input-number v-model="form.growth_value" step-strictly controls-position="right" :min="1" size="small"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="备注信息" prop="notes">
        <el-input
            type="textarea"
            size="small"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            v-model="form.notes">
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AdminEdit',
  data() {
    return {
      coustate: true,
      form: {
        is_credit: '',
        credit: '',
        is_growth_value: '',
        growth_value: '',
        notes: '',
      },
      rules: {},
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {
  },
  methods: {
    handlePic(res) {
      console.log(res)
      const {code, data} = res
      if (code == 200) {
        this.form.pic = data
      }
    },
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '修改'
        this.form = Object.assign({}, row)
      }
      this.dialogFormVisible = true
    },
    selectionStatus(event) {
      console.log(event)
      if (event == 2) {
        this.coustate = true
      } else {
        this.coustate = false
      }
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    // save() {
    //   if (this.form.pic == '') {
    //     this.$message.error('请选择等级图!', 'success')
    //     return
    //   }
    //   this.$refs['form'].validate(async (valid) => {
    //     if (valid) {
    //       const { code, message } = await doEdit({
    //         id: this.form.id,
    //         username: this.form.username,
    //         password: this.form.password,
    //         auth_key: this.form.auth_key,
    //         email: this.form.email,
    //       })
    //       if (code == '200') {
    //         this.$baseMessage(message, 'success')
    //         this.$emit('fetch-data')
    //         this.close()
    //       } else {
    //         this.$baseMessage(message, 'error')
    //         this.$emit('fetch-data')
    //         this.close()
    //       }
    //     } else {
    //       return false
    //     }
    //   })
    // },
  },
}
</script>
